document.addEventListener("DOMContentLoaded", () => {
    // NewsItem read more text

    const newsItems = document.querySelectorAll('.newsView-item');

    for (let i = 0; i < newsItems.length; i++) {
        const newsItem = newsItems[i];
        const moreText = newsItem.querySelector('.more_text');
        if (moreText) {
            const trigger = newsItem.querySelector('.read-more');
            const initialHeight = getAbsoluteHeight(moreText);
            moreText.style.height = 0;

            trigger.addEventListener('click', function(event){
                event.preventDefault;
                
                moreText.style.height = initialHeight + "px";
                trigger.style.display = 'none';
            });
        }

    }
});



function getAbsoluteHeight(el) {

    // Get the DOM Node if you pass in a string
    el = (typeof el === 'string') ? document.querySelector(el) : el;

    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) +
        parseFloat(styles['marginBottom']);

    return Math.ceil(el.offsetHeight + margin);
}
