import 'waypoints/lib/noframework.waypoints';
import { animateValue } from './countanimation.js';

const backdropElements = document.querySelectorAll('.backdrop-area');
createWaypoints(backdropElements);

const countupElements = document.querySelectorAll('.countUpElement .count');
createWaypointCount(countupElements)

function createWaypoints(elements){
    const t = elements;
    
    for (let i = 0; i < t.length; i++) {
        const wayopintElement = t[i];
        
        const waypoint = new Waypoint({
            element: wayopintElement,
            handler: function(direction){
                if(direction == 'down'){
                    this.element.classList.add('waypoint-passed');
                } else {
                    this.element.classList.remove('waypoint-passed');
                }
            },
            offset:'50%'
        });
    }
}

function createWaypointCount(elements){
    const t = elements;
    
    for (let i = 0; i < t.length; i++) {
        const wayopintElement = t[i];
        
        const waypoint = new Waypoint({
            element: wayopintElement,
            handler: function(direction){
                const start = 0;
                const target = this.element.dataset.countvalue;
            
                animateValue(this.element, start, target, 2000);
            },
            offset:'50%'
        });
    }
}