
document.addEventListener("DOMContentLoaded", function() { 
    
  // CONSTANTS
  const APP = document.querySelector('#app');
  const BODY = document.querySelector('body');
  const BTN_HOME = document.querySelector('#home');
  const BTN_LANG = document.querySelector('#lang');
  const BTN_BACK = document.querySelector('#back');
  const BTN_BACK_FRAME = document.querySelector('#frame-back');
  const MENU_LANG = document.querySelector('#menu-lang');
  const LANG_SWITCH = document.querySelectorAll('.lang-switch');

  const COLUMNS = document.querySelectorAll(".frame-col");
  const OPEN_FRAME_2 = document.querySelectorAll(".open-frame-2");
  const OPEN_FRAME_3 = document.querySelectorAll(".open-frame-3");

  const SECOND_FRAME_CONTENT = document.querySelector("#second-frame-content");
  const THIRD_FRAME_CONTENT = document.querySelector("#third-frame-content");


  // EVENT LISTENERS
  if(BTN_HOME) BTN_HOME.addEventListener("click", goHome);
  if(BTN_BACK) BTN_BACK.addEventListener("click", goBack);
  if(BTN_BACK_FRAME) BTN_BACK_FRAME.addEventListener("click", goBack);
  if(BTN_LANG) BTN_LANG.addEventListener("click", openLanguages);


  // FUNCTIONS
  function goHome(event) {
    event.preventDefault();
    closeFrame(SECOND_FRAME_CONTENT);
    closeFrame(THIRD_FRAME_CONTENT);
    BODY.classList.remove('open-second-frame', 'open-third-frame');
  }

  function closeFrame(frames) {
    for (const child of frames.children) {
      if(child.hasAttribute('data-content')) {
        if(child.style.display === "block") {
          child.style.display = "none";
        }
      }
    }
  }

  function goBack(event) {
    event.preventDefault();
    if (BODY.classList.contains('open-second-frame')) {
      if (BODY.classList.contains('open-third-frame')) {
        closeFrame(THIRD_FRAME_CONTENT);
        BODY.classList.remove('open-third-frame');
      } else {
        closeFrame(SECOND_FRAME_CONTENT);
        BODY.classList.remove('open-second-frame');
      }
    }
  }

  function setLanguage(event) {
    event.preventDefault();
    APP.setAttribute('data-lang', this.dataset.setlang);
  }

  function openLanguages() {
    MENU_LANG.classList.toggle("is--open");
    BTN_LANG.classList.toggle("is--active");
  }

  function openSecondFrame(event) {
    event.preventDefault();
    if( SECOND_FRAME_CONTENT.querySelector(`[data-content="${this.dataset.connect}"]`) ) {
      BODY.classList.add('open-second-frame');
      SECOND_FRAME_CONTENT.querySelector(`[data-content="${this.dataset.connect}"]`).style.display = 'block';
    }

  }

  function openThirdFrame(event) {
    event.preventDefault();
    if( THIRD_FRAME_CONTENT.querySelector(`[data-content="${this.dataset.connect}"]`) ) {
      BODY.classList.add('open-third-frame');
      THIRD_FRAME_CONTENT.querySelector(`[data-content="${this.dataset.connect}"]`).style.display = 'block';
    }
  }


  OPEN_FRAME_2.forEach((el) => {
    el.addEventListener("click", openSecondFrame, false);
  });


  OPEN_FRAME_3.forEach((el) => {
    el.addEventListener("click", openThirdFrame, false);
  });


  LANG_SWITCH.forEach((lang) => {
    lang.addEventListener("click", setLanguage, false);
  });

  const maxColumns = COLUMNS.length;
  let counter = 0;
  const speed = 800;
  var timer = setInterval(fun, speed);

  function fun() {

    counter++;

    COLUMNS.forEach((col) => {
      col.classList.remove(`is--active-${col.dataset.col}`);
      if(counter == col.dataset.col) {
        col.classList.add(`is--active-${col.dataset.col}`);
      }
    });

    if(counter == maxColumns) {
      clearInterval(timer);
      restart();
    }

  }

  function restart() {
    counter = 0;
    timer = setInterval(fun, speed);
  }

});