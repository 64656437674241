import Choices from 'choices.js';

document.addEventListener('DOMContentLoaded', event => {

    //  only initiate select plugin on not mobile devices
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

        const selects = document.querySelectorAll('.select select');
        const countrySelects = document.querySelectorAll('.select-country select');

        if (selects.length > 0) {
            for (let i = 0; i < selects.length; i++) {
                const select = selects[i];
                const choices = new Choices(select, {
                    searchEnabled: false,
                    itemSelectText: "",
                    shouldSort: true
                });
            }
        }

        if (countrySelects.length > 0) {
            for (let j = 0; j < countrySelects.length; j++) {
                const countrySelect = countrySelects[j];
                const countryChoices = new Choices(countrySelect, {
                    searchEnabled: false,
                    itemSelectText: "",
                    // position: 'bottom',
                    callbackOnCreateTemplates: function (template) {
                        return {
                            item: (classNames, data) => {
                                const markup = `<span class="lang ${data.value}">${data.value}</span>`
                                return template(`
                                  <div class="${classNames.item} ${
                                  data.highlighted
                                    ? classNames.highlightedState
                                    : classNames.itemSelectable
                                } ${
                                  data.placeholder ? classNames.placeholder : ''
                                }" data-item data-id="${data.id}" data-value="${data.value}" ${
                                  data.active ? 'aria-selected="true"' : ''
                                } ${data.disabled ? 'aria-disabled="true"' : ''}>
                                ${data.value ? markup : ''}${data.label}
                                  </div>
                                `);
                            },
                            choice: (classNames, data) => {
                                const markup = `<span class="lang ${data.value}">${data.value}</span>`
                                return template(`
                                  <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}" data-select-text="${this.config.itemSelectText}" data-choice ${
                                      data.disabled
                                        ? 'data-choice-disabled aria-disabled="true"'
                                        : 'data-choice-selectable'
                                    } data-id="${data.id}" data-value="${data.value}" ${
                                      data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                                    }>
                                    ${data.value ? markup : ''}${data.label}
                                    </div>
                                `);
                            },
                        }
                    }
                });
            }
        }
    }

});

// TODO: Build on change function for .get-subcategories

function categoryCall(e) {
    const langRealURL = ['/typo3/public/de/', '/typo3/public/en/'];
    const selectfield = document.getElementById('categorySelect');
    
    let langParam = 0;
    let xhr = new XMLHttpRequest();
    
    for (let i = 0; i < langRealURL.length; i++) {
        const item = langRealURL[i];
        if(getUrlParameterReal(item) == true){
            langParam = item;
        }
    }
    const additionsURL = langParam+'?type=781623001&q='+e.value;
    
    xhr.open('GET', additionsURL);
    xhr.onload = function() {
        if (xhr.status === 200){
            selectfield.innerHTML = xhr.response;
        }
    }
    xhr.send();
    
    const updateCategory = new Choices(selectfield, {
        searchEnabled: false,
        itemSelectText: "",
    });
}

var getUrlParameterReal = function getUrlParameter(sParam) {
    if (window.location.href.indexOf(sParam) != -1) {
        return true;
    }
};