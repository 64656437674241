import {
    tns
} from 'tiny-slider/src/tiny-slider'; // <-- Workaround for missing exports in dist folder of node module "tiny-slider"

document.addEventListener('DOMContentLoaded', event => {

    const slider3colsContainers = document.querySelectorAll('.slider-3-cols');
    if (slider3colsContainers.length > 0) {
        for (let i = 0; i < slider3colsContainers.length; i++) {
            const slider3colsContainer = slider3colsContainers[i];
            const slider3cols = tns({
                container: slider3colsContainer.querySelector('.slideElements'),
                autoplay: false,
                nav: false,
                arrowKeys: true,
                loop: false,
                autoHeight: true,
                controlsContainer: slider3colsContainer.querySelector('.slider-controls'),
                items: 1,
                lazyload: true,
                responsive: {
                    769: {
                        items: 2,
                        gutter: 8
                    },
                    1025: {
                        mouseDrag: true,
                        items: 3
                    }
                }
            });
        }
    }

    const sliderOverflowContainers = document.querySelectorAll('.slider-overflow');
    if (sliderOverflowContainers.length > 0) {
        for (let j = 0; j < sliderOverflowContainers.length; j++) {
            const sliderOverflowContainer = sliderOverflowContainers[j];
            const sliderOverflow = tns({
                container: sliderOverflowContainer.querySelector('.slideElements'),
                items: 1,
                loop: true,
                center: true,
                nav: false,
                controlsContainer: sliderOverflowContainer.querySelector('.slider-controls'),
                onInit: function (info) {
                    info.slideItems[info.index].classList.add("centered-slide");
                    addPaginationCount(info);
                },
                responsive: {
                    1201: {
                        fixedWidth: 994,
                    }
                }
            });

            sliderOverflow.events.on("transitionStart", function (info) {
                info.slideItems[info.indexCached].classList.remove("centered-slide");
            });

            sliderOverflow.events.on("transitionEnd", function (info) {
                info.slideItems[info.index].classList.add("centered-slide");
                paginate(info);
            });
        }
    }

    const productSliderContainers = document.querySelectorAll('.product-slider');
    if (productSliderContainers.length > 0) {
        for (let k = 0; k < productSliderContainers.length; k++) {
            const productSliderContainer = productSliderContainers[k];
            const productSlider = tns({
                container: productSliderContainer.querySelector('.slide-elements'),
                items: 1,
                loop: true,
                center: true,
                nav: false,
                gutter: 16,
                controlsContainer: productSliderContainer.querySelector('.slider-controls'),
                onInit: function (info) {
                    info.slideItems[info.index].classList.add("centered-slide");
                    addPaginationCount(info);
                },
                responsive: {
                    577: {
                        fixedWidth: 388,
                    }
                }
            });

            productSlider.events.on("transitionStart", function (info) {
                info.slideItems[info.indexCached].classList.remove("centered-slide");
            });

            productSlider.events.on("transitionEnd", function (info) {
                info.slideItems[info.index].classList.add("centered-slide");
                paginate(info);
            });
        }
    }

    const fullsizeSliderContainers = document.querySelectorAll('.slider-fullsize');
    if (fullsizeSliderContainers.length > 0) {
        for (let l = 0; l < fullsizeSliderContainers.length; l++) {
            const fullsizeSliderContainer = fullsizeSliderContainers[l];
            const fullsizeSlider = tns({
                container: fullsizeSliderContainer.querySelector('.slideElements'),
                items: 1,
                controlsContainer: fullsizeSliderContainer.querySelector('.slider-controls'),
                autoHeight: true,
                nav: true,
                navContainer: fullsizeSliderContainer.querySelector('.thumbnails ul'),
                navAsThumbnails: true,
                responsive: {
                    769: {
                        autoHeight: false,
                        loop: false,
                    }
                }
            });

        }
    }

    const jobsListBody = document.querySelector('.jobs-list--body');
    if (jobsListBody) {
        const jobListSlider = tns({
            container: jobsListBody,
            items: 1,
            nav: false,
            controlsContainer: document.querySelector('.jobs-list .slider-controls'),
            responsive: {
                601: {
                    items: 2,
                    gutter: 10
                },
                769: {
                    disable: true
                }
            }
        })
    }
});

function addPaginationCount(info) {
    const container = info.controlsContainer;
    const prev = container.querySelector('.prev');

    const count = info.slideCount;
    let current = info.displayIndex;

    let pagination = "";
    pagination = document.createElement('span');
    pagination.innerHTML = current + " / " + count;

    prev.after(pagination);
}

function paginate(info) {
    const container = info.controlsContainer;
    const countElement = container.querySelector('span');

    countElement.innerHTML = info.displayIndex + " / " + info.slideCount;
}
