if (isMobileDevice()) {
    document.querySelector('body').classList.add('mobile-device');
}



import "./modules/mobilenav";
import "./modules/mobilenavExtend";
import "./modules/deferImages";
import "./modules/formselects";
import "./modules/sliders";
import "./modules/lightboxes";
import "./modules/waypoints";
import "./modules/maps";
import "./modules/newsView";
import "./modules/accordions";
import "./modules/accordion";
import "./modules/fixedNav";
import "./modules/deferImages";                       
import  "micromodal";

import "../../export/src/javascripts/src/main.js";

// document fully loaded
document.addEventListener('DOMContentLoaded', function () {
	MicroModal.init();
	
    document.querySelector('body').classList.remove('no-js');

    const heroVideoParents = document.querySelectorAll('.home_hero .product_type_element');
    if (heroVideoParents.length > 0) {
        for (let i = 0; i < heroVideoParents.length; i++) {
            const element = heroVideoParents[i];
            const video = element.querySelector('video');

            loadVideoSourceFromDataset(video);

            element.addEventListener("mouseover", function () {
                video.play();
            });

            element.addEventListener("mouseleave", function () {
                setTimeout(function () {
                    video.pause();
                }, 300);
            });
        }
    }

    // Aside Panel
    const asidePanel = document.querySelector('.slide-in-box');
    const trigger = asidePanel.querySelector('.trigger-open');

    if (!document.querySelector('.home_hero') && window.innerWidth > 1024) {
        
        // write cookie
        var showAsidePanel = true;
        var sesKey = "aside_panel_status";

        document.cookie.split(";").map(function (v, i) {
            if (v.split("=")[0].trim() == sesKey) {
                showAsidePanel = false;
            }
        });

        if(showAsidePanel) {
            asidePanel.classList.toggle("open");
            document.cookie = sesKey + "=1;path=/";
        }

    }

    trigger.addEventListener('click', function () {
        asidePanel.classList.toggle('open');
    });

    document.addEventListener("click", (evt) => {
        let targetElement = evt.target; // clicked element
        do {
            if (targetElement == asidePanel) {
                // This is a click inside. Do nothing, just return.
                return;
            }
            // Go up the DOM
            targetElement = targetElement.parentNode;
        } while (targetElement);
        // This is a click outside.
        asidePanel.classList.remove('open');
    });
    document.addEventListener('scroll', function (e) {
        if (asidePanel.classList.contains('open')) {
            asidePanel.classList.remove('open');
        }
    });
    // END Aside Panel #########

    // Smooth scrolling
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
});

window.addEventListener("load", function () {
          for (
              var i = Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
                  n = document.querySelectorAll(".interactive--info li"),
                  e = document.querySelectorAll(".interactive--contentArea .interactive--content"),
                  t = 0;
              t < e.length;
              t++
          )
              e[t].style.display = "none";
          for (
              var o = function (e) {
                      var t = n[e];
                      !(function (e, t) {
                          var i = e.dataset.pos;
                          if ((t <= 1025 && (e = e.querySelector(".interactive--hotspot")), i)) {
                              var n = i.split(",");
                              (e.style.top = n[0] + "%"), (e.style.left = n[1] + "%"), e.clientWidth, e.getClientRects()[0].left;
                          }
                      })(t, i),
                          t.addEventListener("click", function () {
                              !(function (e, t) {
                                  for (var i = document.querySelectorAll(".interactive--contentArea .interactive--content"), n = 0; n < i.length; n++) {
                                      i[n].style.display = "none";
                                  }
                                  (i[t].style.display = "block"), i[t].scrollIntoView({ behavior: "smooth", block: "nearest" });
                              })(0, e);
                          });
                  },
                  s = 0;
              s < n.length;
              s++
          )
              o(s);
      });


function loadVideoSourceFromDataset(videoElement) {
    const t = videoElement;
    const sourceTag = t.querySelector('source');
    const source = sourceTag.dataset.src;

    sourceTag.src = source;
    t.load();
}

function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};
