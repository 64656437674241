import 'mmenu-js';

document.addEventListener("DOMContentLoaded", () => {

    if (isMobileDevice() || window.innerWidth < 1025) {

        const metanav_1 = document.querySelector('nav.meta .shop');
        const langswitch = document.querySelectorAll('nav.meta .langswitch ul li');
        const navTrigger = document.querySelector('.mobile-menu');

        let langswitchactive = document.createElement('option');
        langswitchactive.setAttribute('selected', '');
        langswitchactive.innerText = document.querySelector('nav.meta .langswitch .active_lang').innerText;
        let langswitchWrap = document.createElement('div');
        langswitchWrap.classList.add('select', 'fieldwrap');
        let langswitchNew = document.createElement('select');
        langswitchNew.appendChild(langswitchactive);
        // langswitchNew.setAttribute( "onchange", "this.options[this.selectedIndex].value && (window.location = this.options[this.selectedIndex].value);" );
        langswitchNew.setAttribute( "onchange", "window.document.location.href=this.options[this.selectedIndex].value;" );

        for (let i = 0; i < langswitch.length; i++) {
            const element = langswitch[i];
            const option = document.createElement('option');
            option.value = element.children[0].href;
            option.innerHTML = element.innerText;
            langswitchNew.appendChild(option);
        }

        langswitchWrap.appendChild(langswitchNew);

        const menu = new Mmenu("#mainmenu", {
            "extensions": [
                "fullscreen",
                "position-right",
                "position-front"
            ],
            // drag: {
            //     open: true
            // },
            "navbars": [{
                "position": "bottom",
                "content": [
                    metanav_1.innerHTML,
                    langswitchWrap
                ]
            }],
            navbar: {
                title: navTrigger.innerText
            }
        });

        const api = menu.API;

        navTrigger.addEventListener("click", (evnt) => {
            evnt.preventDefault();
            api.open();
        });
    }
});

function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};
