document.addEventListener("DOMContentLoaded", () => {
    const metanav_career = document.querySelector("nav.meta .career");
    const metanav_career_link = metanav_career.firstElementChild;
    const metanav_career_submenu = metanav_career.lastElementChild;

    const metanav_search = document.querySelector("nav.meta .search");
    // const mm_navbar_bottom = document.querySelector(".mm-navbars_bottom");
    const logo = document.querySelector(".logo");

    const listview = document.querySelector(".mm-listview");
    const panels = document.querySelector('.mm-panels');

    if (isMobileDevice() || window.innerWidth < 1025) {
        logo.after(metanav_search.firstElementChild);

        /*
            mm_navbar_bottom
                .querySelector(".link-shop")
                .after(metanav_career.firstElementChild);
            */

        // add career link into mobile-menu at bottom
        const listitem = document.createElement("li");
        listitem.innerHTML = `${metanav_career_link.outerHTML}<a class="mm-btn mm-btn_next mm-listitem__btn" href="#mm-50"><span class="mm-sronly">Open submenu</span></a>`;
        listitem.setAttribute("class", "producttype_6 hasSub mm-listitem");
        listitem.querySelector("a").setAttribute("class", "mm-listitem__text");
        listview.append(listitem);

        // create custom panel for #mm-50
        // fill with submenu carrer
        const panelItem = document.createElement("div");
        panelItem.innerHTML = `<div class="mm-navbar mm-navbar_sticky"><a class="mm-btn mm-btn_prev mm-navbar__btn" href="#mm-1" aria-haspopup="true" aria-owns="mm-1"><span class="mm-sronly">Close submenu</span></a><a class="mm-navbar__title" href="#mm-1" aria-hidden="true"><span>${metanav_career_link.innerText}</span></a></div>${metanav_career_submenu.outerHTML}`;
        panelItem.querySelector("ul").classList.remove("subNav");
        panelItem.querySelector("ul").setAttribute("class", `lvl-2 subCount-${metanav_career_submenu.childElementCount} mm-listview`);
        panelItem.setAttribute("id", "mm-50");
        panelItem.setAttribute("class", "mm-panel mm-hidden");
        panelItem.setAttribute("aria-hidden", "true");

        const panelItemListItems = panelItem.querySelector(".mm-listview");
        panelItemListItems.querySelectorAll("li").forEach( li => li.setAttribute("class", "mm-listitem"));
        panelItemListItems.querySelectorAll("a").forEach( a => a.setAttribute("class", "mm-listitem__text"));

        panels.append(panelItem);

    }
});

function isMobileDevice() {
    return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
    );
}
