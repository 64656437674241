let scrollDistance = 0;
const bodyContainer = document.querySelector('.page');

window.addEventListener('load', function(){
    fixedNavifunction();
});

window.addEventListener('scroll', function(){
    fixedNavifunction();
});

function fixedNavifunction(){
    scrollDistance = window.scrollY;
    
    if(scrollDistance >= 500){
        bodyContainer.classList.add('fixed-nav');
    } else {
        bodyContainer.classList.remove('fixed-nav');
    }
}