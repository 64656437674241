document.addEventListener("DOMContentLoaded", () => {

    const items = document.querySelectorAll(".accordion button.btn");
    let i, height;

    function toggleAccordion() {
        
        
        getHeight(this.nextSibling);

        const itemToggle = this.getAttribute("aria-expanded");

        
        
        for (i = 0; i < items.length; i++) {
            items[i].setAttribute("aria-expanded", "false");
            items[i].nextSibling.style.maxHeight = null;
        }
        

        if (itemToggle == "false") {
            this.setAttribute("aria-expanded", "true");
            this.nextSibling.style.maxHeight = `${height}px`;
        }
    }

    function getHeight(item) {
        height = item.querySelector(".accordion-content-inner").offsetHeight;
        return height;
    }

    items.forEach((item) => item.addEventListener("click", toggleAccordion));



});
