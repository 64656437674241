import Isotope from 'isotope-layout';

document.addEventListener('DOMContentLoaded', (event) => {

    const elem = document.querySelector('.newsView');
    if (elem) {
        const viewContainer = elem.querySelector('.newsView--container');

        if (window.innerWidth > 660) {

            const view = new Isotope(viewContainer, {
                //options
                itemSelector: '.newsView-item',
                layoutMode: 'fitRows',
                // percentPosition: true
            });

            const viewoptions = elem.querySelectorAll('.display-option');

            for (let i = 0; i < viewoptions.length; i++) {
                viewoptions[i].addEventListener('click', function () {
                    const layoutData = this.dataset.view;
                    changeViewStyle(layoutData, viewoptions, view, viewContainer);
                });
            }
        } else {
            viewContainer.classList.add('no-grid');
        }

    }

});

function changeViewStyle(layoutData, allelements, isotopeElement, viewContainer) {

    for (let i = 0; i < allelements.length; i++) {
        const eachElement = allelements[i];
        eachElement.classList.remove('active');

        if (eachElement.dataset.view == layoutData) {
            eachElement.classList.add('active');
        }
    }

    if (layoutData == "grid") {
        isotopeElement.arrange({
            layoutMode: 'fitRows'
        });
        viewContainer.classList.remove('listview');
        viewContainer.classList.add('gridview');
    }

    if (layoutData == "list") {
        isotopeElement.arrange({
            layoutMode: 'vertical'
        });
        viewContainer.classList.remove('gridview');
        viewContainer.classList.add('listview');
    }

    isotopeElement.layout('layout');
}
