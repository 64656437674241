window.addEventListener("ucEvent", function (e) { 
  if( e.detail && e.detail.event == "consent_status") {
	if ( document.querySelector("#maps-1") ) { 
		if(e.detail['Google Maps'] === true) {
			var script = document.createElement('script');
			script.src = 'https://maps.google.com/maps/api/js?key=AIzaSyDbN0zuVTO-g7-NUvp1RjKNMAvYvm1Kpf0&v=3.47';
			script.async = true;
			document.head.appendChild(script);         
			loadJSONContent(document.querySelector("#maps-1").dataset.json);           
		} else {
		  initMap(false);
		}
	}
  } 
});		

if (document.querySelector("#maps-1-baidu")) {
	loadJSONContentForBaidu(document.querySelector("#maps-1-baidu").dataset.json);   
}

function loadJSONContentForBaidu(filePath) {
    let xobj = new XMLHttpRequest();
    xobj.open('GET', filePath, true);
    xobj.onload = function () {
        if (this.status == 200) {
            let locations = JSON.parse(this.response); 
            initBaiduMap(locations);
        }
    }
    xobj.send();
}


function loadJSONContent(filePath) {
    let xobj = new XMLHttpRequest();
    xobj.open('GET', filePath, true);
    xobj.onload = function () {
        if (this.status == 200) {
            let locations = JSON.parse(this.response);
            initMap(locations);
        }
    }
    xobj.send();
}

function initMap(map_locations) {
  var mapStyles = [
		{ featureType: "water", elementType: "geometry", stylers: [{ color: "#e9e9e9" }, { lightness: 17 }] },
		{ featureType: "landscape", elementType: "geometry", stylers: [{ color: "#f5f5f5" }, { lightness: 20 }] },
		{ featureType: "road.highway", elementType: "geometry.fill", stylers: [{ color: "#ffffff" }, { lightness: 17 }] },
		{ featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }] },
		{ featureType: "road.arterial", elementType: "geometry", stylers: [{ color: "#ffffff" }, { lightness: 18 }] },
		{ featureType: "road.local", elementType: "geometry", stylers: [{ color: "#ffffff" }, { lightness: 16 }] },
		{ featureType: "poi", elementType: "geometry", stylers: [{ color: "#f5f5f5" }, { lightness: 21 }] },
		{ featureType: "poi.park", elementType: "geometry", stylers: [{ color: "#dedede" }, { lightness: 21 }] },
		{ elementType: "labels.text.stroke", stylers: [{ visibility: "on" }, { color: "#ffffff" }, { lightness: 16 }] },
		{ elementType: "labels.text.fill", stylers: [{ saturation: 36 }, { color: "#333333" }, { lightness: 40 }] },
		{ elementType: "labels.icon", stylers: [{ visibility: "off" }] },
		{ featureType: "transit", elementType: "geometry", stylers: [{ color: "#f2f2f2" }, { lightness: 19 }] },
		{ featureType: "administrative", elementType: "geometry.fill", stylers: [{ color: "#fefefe" }, { lightness: 20 }] },
		{ featureType: "administrative", elementType: "geometry.stroke", stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }] },
	];
  var map = new google.maps.Map(
      document.getElementById('maps-1'), {
          styles: mapStyles,
          center: { lat: 49.00266817613201, lng: 8.408255422816318 },
          zoom: 1
      }
  );
  
  
  if ( map_locations ) {

        var bounds = new google.maps.LatLngBounds();
      var infowindow = new google.maps.InfoWindow({
          pixelOffset: new google.maps.Size(120,320)
      });
      var markerDir = "/typo3conf/ext/sitetemplate/Resources/Public/media/icons/marker/";
      var icons = {
      	type_1: { icon: { url: markerDir + "type_1.svg", scaledSize: new google.maps.Size(22, 30) } },
      	type_2: { icon: { url: markerDir + "type_2.svg", scaledSize: new google.maps.Size(22, 30) } },
      	type_3: { icon: { url: markerDir + "type_3.svg", scaledSize: new google.maps.Size(22, 30) } },    
      };
      var bounds = new google.maps.LatLngBounds();
      var infoWindow = new google.maps.InfoWindow({ pixelOffset: new google.maps.Size(120, 320) });
  
  
      let filterData = {
          "location_type": 0,
          "location_uid": 0,
          "location_continent": 0
      };
  
      const filters = document.querySelectorAll('.filter select');
      

  
      for (let i = 0; i < filters.length; i++) {
          const select = filters[i];
          select.addEventListener('change', function(){
              let filteredLocations = [];
              const filterType = this.name;
              const filterValue = this.value;
              filterData[filterType] = filterValue;
              var boundsSet = new google.maps.LatLngBounds();
              var setBounds = false;

              const lands = document.getElementById('land');
              const continents = document.getElementById('kontinent');

              if ( filterType == 'location_continent' && filterValue > 0 ) {
                    lands.selectedIndex = 0;
                    for(i=0; i<lands.options.length;i++){
                      lands.options[i].style.display = "block";
                    }
              }
              for (var i in map_locations) {
                if ( filterType == 'location_uid' && filterValue > 0) {
                    var locTmp = map_locations[i].location_uid.split(",");
                    for ( var n in locTmp  ) {
                      if ( locTmp[n].trim() == filterValue ) {
                          var lat = map_locations[i].location_lat;
                          var lng = map_locations[i].location_lng;
                          var markerPosition = new google.maps.LatLng(lat, lng);      
                          setBounds = true;
                          boundsSet.extend(markerPosition);              
                      }
                    }  
                } else {

                  if ( filterType == 'location_continent' && filterValue > 0 && map_locations[i].location_continent == filterValue ) {

                      var lat = map_locations[i].location_lat;
                      var lng = map_locations[i].location_lng;
                      var markerPosition = new google.maps.LatLng(lat, lng);
                      setBounds = true;
                      boundsSet.extend(markerPosition);

                      continents.options[0].style.display = 'none';
                      for(i=0; i<lands.options.length;i++){
                          
                          if(lands.options[i].getAttribute('data-custom-properties') == filterValue) {
                            lands.options[i].style.display = "block";
                          } else {
                            lands.options[i].style.display = "none";
                          }
                      }          
                           

                      
                  }   
                }
              }
              if ( setBounds ) {
                map.fitBounds(boundsSet);
              } 
          });
      }
      
      
      for (var i in map_locations) {
          var lat = map_locations[i].location_lat;
          var lng = map_locations[i].location_lng;
          var locType = map_locations[i].location_type;
          var locInfo = map_locations[i].location_info.info_areas.replace('&amp;','&');
          var markerPosition = new google.maps.LatLng(lat, lng);
          bounds.extend(markerPosition);
          
          var marker = new google.maps.Marker({
              position: markerPosition,
              icon: icons[locType].icon,
              map: map,
              title: locInfo
          });
          
  
        (function(marker, i) {
          google.maps.event.addListener(marker, 'click', function() {
          
                var infowindow = new google.maps.InfoWindow({
          				content: '        \n\t                    <div class="infowindow">\n\t                    <header>\n\t                        <div class="flag"></div>\n\t                        <h3>'
          				.concat(map_locations[i].title, '</h3>\n\t                        <div class="timestamp">')
          				.concat(map_locations[i].location_time, '</div>\n\t                    </header>\n\t                    <div class="content">\n\t                        <h4>')
          				.concat(map_locations[i].location_info.info_title, "</h4>\n\t                        <p>")
          				.concat(map_locations[i].location_info.info_adress, '</p>\n\t                        <ul class="contact-data">\n\t                            <li class="phone"><a href="tel:')
          				.concat(map_locations[i].location_info.info_phone_url, '">')
          				.concat(map_locations[i].location_info.info_phone, '</a></li>\n\t                            <li class="fax">')
          				.concat(map_locations[i].location_info.info_fax, '</li>\n\t                            <li class="mail"><a href="mailto:')
          				.concat(map_locations[i].location_info.info_mail, '">')
          				.concat(map_locations[i].location_info.info_mail,'</a></li>\n\t                        </ul>\n\t                        <div class="marker-icon"><img src="/typo3conf/ext/sitetemplate/Resources/Public/media/icons/marker/')
                  .concat(map_locations[i].location_type, '.svg" alt="" height="30" width="22"></div>\n\t                    </div>\n\t                    </div>\n\t                '),
        			});
          
              infowindow.open(map, marker);
              
              map.setZoom(15);
              map.setCenter(this.getPosition());
    
              google.maps.event.addListener(infowindow, 'closeclick', function(){
                  map.fitBounds(bounds);
                  map.panToBounds(bounds);
              });
            });
          })(marker, i);
          
    }
    
    map.fitBounds(bounds);
    map.panToBounds(bounds);


      const submit = document.querySelector(".btn");
      submit.addEventListener("click", function(e) {

        const continent_value = document.getElementById('kontinent').value;
        // const cpuntry_value = document.getElementById('land').value;
  
        const places = document.querySelectorAll(".placesList--place");
        const territories = document.querySelectorAll("[class^=territory-]");

        places.forEach((placeItem) => {

          placeItem.style.display = "block";

          let placeItem_continent = placeItem.getAttribute('data-continent');
          // let placeItem_country = placeItem.getAttribute('data-country');

          if(continent_value == placeItem_continent) {
            placeItem.style.display = "block";
          } else {
            placeItem.style.display = "none";
          }
          
        });

        territories.forEach((territoryItem) => {
          territoryItem.style.display = "block";
          territoryItem.querySelectorAll(".placesList--place").forEach((territoryPlaceItem) => {
            if(territoryPlaceItem.style.display === 'none') {
              territoryItem.style.display = "none";
            }
          });

        });

	  });
    
  }
}

function initBaiduMap (map_locations) {
	var map = new BMap.Map("maps-1-baidu"); // t
	map.addControl(new BMap.MapTypeControl({ mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP] }));
	map.setCurrentCity("Beijing");
	map.enableScrollWheelZoom(true);
	map.setMapStyle({ style: "grayscale" });
	var mapPoints = new Array();
	var mapMarkers = new Array();
	
	var mapWindows = new Array();
	for (var s in map_locations) {
		
        var lat = map_locations[s].location_lat; // a
        var lng = map_locations[s].location_lng; // l
		// map_locations = e
		var mapPoint = new window.BMap.Point(lng, lat); // i
		mapMarkers[s] = new window.BMap.Marker(mapPoint); // n
		
		
		map.addOverlay(mapMarkers[s]); 
		map_locations[s].location_type;
		var mapLabel = new window.BMap.Label(map_locations[s].title, { offset: new window.BMap.Size(20, -10) }); // l
		mapLabel.setStyle({ color: "blue", fontSize: "12px", height: "20px", margin: 0, padding: 0, border: 0, lineHeight: "20px", fontFamily: "Microsoft Yahei" });
		mapMarkers[s].setLabel(mapLabel);
		mapWindows[s] = new window.BMap.InfoWindow(
			'<div class="infowindow"><header><div class="flag"></div><h3>' +
				map_locations[s].title +
				'</h3><div class="timestamp">' +
				map_locations[s].location_time +
				'</div></header><div class="content"><h4>' +
				map_locations[s].location_info.info_title +
				"</h4><p>" +
				map_locations[s].location_info.info_adress +
				'</p><ul class="contact-data"><li class="phone"><a href="tel:' +
				map_locations[s].location_info.info_phone_url +
				'">' +
				map_locations[s].location_info.info_phone +
				'</a></li><li class="fax">' +
				map_locations[s].location_info.info_fax +
				'</li><li class="mail"><a href="mailto:' +
				map_locations[s].location_info.info_mail +
				'">' +
				map_locations[s].location_info.info_mail +
				"</a></li></ul></div></div>"
		); // o
		mapPoints[s] = mapPoint;
	}
	for ( var s in mapMarkers ) {
		addBaiduMarkerWindow ( mapMarkers[s], mapWindows[s] );
	}
	map.setViewport(mapPoints);
	
}

function addBaiduMarkerWindow ( bMarker, bWindow ) {
		bMarker.addEventListener("click", function () {
			this.openInfoWindow(bWindow);
		});	
}